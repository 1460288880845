import React from 'react';
import useGlobalState from '../customHooks/globalState';
import ProgressTracker from '../components/progressTracker';
import Footer from '../components/footer';

import './css/meet.css';
function Meet() {
    const { personas } = useGlobalState();
    return (
        <>
            <div className='component-wrapper'>
                

                <div className='header header-bg-flex'>
                        <h1 className="two-thirds">Meet the class of <br className="hide-mobile"></br>Millennium Middle School</h1>
                        <p className='header-copy two-thirds half'>These nine classmates have been around for a while. <br className="hide-mobile"></br>You may have heard of them!</p>
                </div>

                <div className="persona-container two-thirds">
                {personas.map((persona, i) => (
                    <div className='persona-card' key={i}>
                        <img src={persona.img} alt={persona.alt}></img>
                        <p className='meet-name'>{persona.name}</p>
                    </div>
                ))}
                </div>

                <div className='mission two-thirds'>
                    <h2 style={{ paddingBottom: '6vh', textAlign: 'center'}}>Here's your mission</h2>
                    <div className='mission-cols full'>
                        <div className='mission-block'>
                            <img className="num-offset" src='/img/no_1.png' alt="A pink handwritten number 1"></img>
                            <h4>Predict the Winner</h4>
                            <p className='small'>Vote for who you think should win which award. You will compare your award picks with the algorithm results later.</p>
                        </div>
                        <div className='mission-block second-block'>
                            <img className="num-offset" src='/img/no_2.png' alt="A pink handwritten number 2"></img>
                            <h4>Train the Most Likely Machine</h4>
                            <p className='small'>Train the Most Likely Machine to figure out which personality traits match each award so that it can pick the winners. </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Meet