import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import './App.css';

//Import App Components
import Landing from './components/landing';
import Objectives from './components/objectives';
import Introducton from './components/introduction';
import Meet from './components/meet';
import Predictions from './components/predictions';
import { TraitDropper } from './components/traitDropper';
import FineTuner from './components/fineTuner';
import Results from './components/results';
import Reflection from './components/reflection';
import Apply from './components/apply';
import Recap from './components/recap';

//Import Global Header
import ScrollToTop from './customHooks/ScrollToTop';
import ProgressTracker from './components/progressTracker';



const App = () => {
  
  const pathname = window.location.pathname

  return (
    <>
      <ScrollToTop>
      <Route path="/" render={(props) => (props.location.pathname !== "/") && <ProgressTracker />}></Route>
        <Route exact path="/">
          <Landing />
        </Route>
        <Route exact path="/objectives"><Objectives /></Route>
        <Route exact path="/introduction"><Introducton /></Route>
        <Route exact path="/meet-the-class"><Meet /></Route>
        <Route exact path="/predict-winners"><Predictions /></Route>
        <Route exact path="/create-algorithm"><TraitDropper /></Route>
        <Route exact path="/tune-algorithm"><FineTuner /></Route>
        <Route exact path="/results"><Results /></Route>
        <Route exact path="/reflection"><Reflection /></Route>
        <Route exact path="/applying"><Apply /></Route>
        <Route exact path="/recap"><Recap /></Route>
      </ScrollToTop>
      </>

  )
}

export default App
