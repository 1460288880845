import React, { useState, Fragment } from 'react';
import useGlobalState from '../customHooks/globalState';
import calcResults from '../algorithm/calculateResults';

import ProgressTracker from './progressTracker';
import Footer from '../components/footer';
import ResultsModal from './resultsModal';
import ErrorScreen from './errorScreen';

import './css/results.css'

function Results() {
    const { superlatives, personas, personasById } = useGlobalState();
    const [modal, toggleModal] = useState({
        model1: false,
        model2: false,
        model3: false
    });

    const results = calcResults(superlatives, personas)

    if (superlatives.some(superlative => superlative.predicted === null)) {
        return (
            <>
                <ErrorScreen />
            </>
        )
    } else {

        return (
            <>
                <div className='component-wrapper'>
                    <div className='header header-bg-flex'>
                        <h1 className="three-quarters padding-bottom-6">Your algorithm's winners</h1>
                    </div>
                    <div className='flex-container white_bg'>
                        <div className='results-container row three-quarters max-wide'>
                            {superlatives.map(superlative => {
                                const winningPersonaId = results.getSuperlativeWinner(superlative.id)
                                const winningPersona = personasById[winningPersonaId]
                                const predictedPersona = personasById[superlative.predicted]
                                return (
                                    <Fragment key={superlative.id}>
                                        <div className='result'>
                                            <div className='result-header'>
                                                <img className='icon' src={superlative.icon} alt={superlative.alt}></img>
                                                <h5 className='lilac'>{superlative.subtitle}</h5>
                                                <h4>{superlative.title}</h4>
                                            </div>
                                            <img src={winningPersona.img} alt={winningPersona.alt}></img>
                                            <div className='winner-title'>{winningPersona.name}</div>
                                          
                                            <p>You Predicted: <br></br>{predictedPersona.name}</p>
                                            <button onClick={() => toggleModal(`model${superlative.id}`)}>Learn Why</button>
                                            <div className="divider"></div>
                                        </div>
                                        {modal === `model${superlative.id}` ?
                                            <ResultsModal id={superlative.id} results={results} toggleModal={toggleModal} superlative={superlative}></ResultsModal> :
                                            <div></div>
                                        }
                                    </Fragment>
                                )
                            })}
                        </div>
                    </div>
                    <div className="questions-container two-thirds max-wide">
                        <h2>Did any of your winners surprise you?</h2>
                        <div className="winner-questions">
                            <p>How did you pick which personality traits go with which award?</p>
                            <p>Were you basing your decision-making on facts or opinions?</p>
                            <p>Do you think everyone will agree with the features you picked?</p>
                            <p>Do you think the features describe each person completely?</p>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}

export default Results