import React from 'react';
import Footer from '../components/footer';
import './css/recap.css'


function Recap() {
    return (
        <>
            <div className='component-wrapper'>
                <div className='header header-bg-flex'>
                    <h1>Let's review</h1>
                </div>
                    <div className='recap-container'>
                        <div className='recap-body white_bg two-thirds'>
                            <div className='recap-point'>
                                <div className='left-col'>
                                    <img src='/img/what_illo.png' alt="Illustration of a frosted strawberry cake on a dessert stand"></img>
                                </div>
                                <div className='right-col'>
                                    <h2>What are algorithms?</h2>
                                    <p>Algorithms are a set of instructions that change one set of information to produce a new set of information – like a recipe!</p>
                                    <ul className='recap-ul'>
                                        <li>In computers, these instructions are written in code and made automatically repeatable.</li>
                                        <li>Computers use algorithms to organize large amounts of information and make decisions quickly.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='recap-point'>
                                <div className='left-col'>
                                    <img src='/img/used_illo.png' alt="Illustration of a laptop. The laptop screen shows a search engine with a tennis shoe as a result"></img>
                                </div>
                                <div className='right-col'>
                                    <h2>Why do we use them?</h2>
                                    <p>Algorithms can help us make decisions and solve problems more efficiently and easily.</p>
                                    <ul className='recap-ul'>
                                        <li>For example, computer algorithms are used to help protect your information when shopping online.</li>
                                        <li>Computer algorithms are also sometimes used to predict the future — but they can make mistakes.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='recap-point'>
                                <div className='left-col'>
                                    <img src='/img/bias_illo.png' alt="Playful illustration of a man and woman standing atop two bar-chart bars"></img>
                                </div>
                                <div className='right-col'>
                                    <h2>What is algorithmic bias?</h2>
                                    <p>Computer algorithms can make mistakes and create unfair results. This is called algorithmic bias.</p>
                                    <ul className='recap-ul'>
                                        <li>Algorithms don’t have all the information. They make decisions using only the information they are given.</li>
                                        <li>Algorithms are based on the opinions of the people who create them.</li>
                                        <li>Algorithms repeat their instructions. This mean they can multiply their mistakes over and over.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className='recap-point'>
                                <div className='left-col'>
                                    <img src='/img/why_illo.png' alt="Illustration of an open envelope with an unfolded sheet of paper that says 'DENIED'"></img>
                                </div>
                                <div className='right-col'>
                                    <h2>Why does it matter?</h2>
                                    <p>We use algorithms every day at school, work, and home. This means algorithmic bias has a powerful impact on the way we live.</p>
                                    <ul className='recap-ul'>
                                        <li>When algorithms are used to predict our future (where we go to school, our popularity, and whether we might get into trouble) we need to make sure they don’t make mistakes.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='bottom-highlight'>
                            <div className='text-container'>
                                <p className='large half max-wide'><span className='first-word'>Algorithms </span>are amazing tools to help us solve problems,
                        but they are not perfect. It is up to us to create algorithms carefully, and decide when we should – and shouldn’t – use them.
                                </p>
                            </div>
                        </div>
                    </div>
                <Footer />
            </div>
        </>
    )
}

export default Recap