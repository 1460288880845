import React from 'react';
import Footer from '../components/footer';

import './css/reflection.css';

function Reflection() {
    return (
        <>
            <div className='component-wrapper header-img'>

                    <div className='reflect-header header secondary-header img-bg header-bg-flex'>
                        <span role="img" aria-label="Playful illustration of a man and woman standing atop two bar-chart bars"></span>
                        <h1 className='main-title half'>How accurate was your algorithm?</h1>
                        <p className='header-copy half title-text'>Computer algorithms can make mistakes and create unfair results. This is called algorithmic bias. Let’s look at three reasons why algorithms sometimes get it wrong.</p>

                    </div>
                    <div className='reflection-container'>
                        <div className='common-mistakes white_bg'>
                            <div className='mistakes two-thirds'>
                                <div className='mistake one'>
                                    <span role="img" aria-label="Handwritten number 1"></span>
                                    <h4>Algorithms don’t have all the information</h4>
                                    <p className="small">The Most Likely Machine only knows as much as you teach it. Beyond the characteristics that you picked, it doesn’t know all the other factors that make up someone’s personality. <br /><br />Algorithms make decisions based on the data that you share with them. This imperfect information can make algorithms biased.</p>
                                </div>
                                <div className='mistake two'>
                                    <span role="img" aria-label="Handwritten number 2"></span>
                                    <h4>Algorithms reflect human opinions</h4>
                                    <p className="small">Algorithms are based on the opinions of the people who create them. When you trained the Most Likely Machine, you taught it to think like you and follow your preferences.<br /><br />Because all algorithms are created by people, it’s impossible to remove their opinions from the decisions that the algorithms make. These opinions can also make algorithms biased.</p>
                                </div>
                                <div className='mistake three'>
                                    <span role="img" aria-label="Handwritten number 3"></span>
                                    <h4>Algorithms can repeat and multiply mistakes</h4>
                                    <p className="small">Algorithms repeat their instructions, so they also repeat and multiply their mistakes. The Most Likely Machine categorized 9 historic figures, but imagine using it to categorize everyone at your school, city or state.<br /><br /> Even one small error in an algorithm can have a huge impact.</p>
                                </div>
                            </div>
                        </div>
                    </div>

            </div>
            <Footer />
        </>


    )
}

export default Reflection