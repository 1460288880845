import React from 'react';
import { useHistory } from 'react-router-dom';
import useGlobalState from '../customHooks/globalState';

import './css/errorScreen.css';
function ErrorScreen() {
    const { steps, setCurrentStep } = useGlobalState();
    let history = useHistory();

    function goBack() {
        let currentStep = 4;
        setCurrentStep(currentStep);
        history.push(`${steps[currentStep].url}`)
    }


    return (
        <>
            <div className='error-container'>
                    <div className='error-body two-thirds'>
                        <h1>Whoops!</h1>
                        <h4>Looks like something went wrong.</h4>
                        <p className='two-thirds'>This (usually) happens because you refreshed the browser while creating your algorithm and earlier choices were lost.</p>
                        <button onClick={goBack}>Back to Predictions</button>
                    </div>
            </div>
        </>
    )
}

export default ErrorScreen;