import React from 'react';
import useGlobalState from '../customHooks/globalState';

import './css/resultsModal.css';
import { ReactComponent as CloseSVG } from '../img/close.svg';

const ResultsModal = (props) => {
    const { superlative, toggleModal, results } = props;
    const { traitsById, superlatives, personas, personasById } = useGlobalState();

    const winningPersonaId = results.getSuperlativeWinner(superlative.id)
    const winningPersona = personasById[winningPersonaId]
    const winningScore = results.getScore(superlative.id, winningPersonaId)
    const predictedPersona = personasById[superlative.predicted]
    const maxScore = 3 * 9

    return (
        <div>
            <div className='results__Overlay'>
                <div className='modal_wrapper'>
                    <div className='closeIcon' onClick={() => toggleModal(false)}><span role="icon" aria-label="Close Icon"></span><CloseSVG /></div>
                    <div className='winner-bio'>
                        {/* <img className='winning-img show-mobile' src={winningPersona.img}></img> */}
                        <div className="test-img-wrapper hide-mobile hide-tablet">
                            <div className="test-img-outer hide-mobile hide-tablet">
                                <img className="test-img-inner hide-mobile" src={winningPersona.img} alt={winningPersona.alt}></img>
                            </div>
                        </div>
                        <div className='winner-details'>
                            <img className='show-mobile' src={winningPersona.img} alt={winningPersona.alt}></img>
                            <img className='icon award-icon' src={superlative.icon} alt={superlative.alt}></img>
                            <h2 className="winning-name">{winningPersona.name}</h2>
                            <p>{winningPersona.blurb}</p>
                            <p className='quote'>{winningPersona.quote}</p>
                        </div>
                    </div>
                    <div className='runner-ups'>
                        <h4>The award runner-ups</h4>
                        {personas.sort((a, b) => results.scoreLookup[superlative.id][b.id] - results.scoreLookup[superlative.id][a.id]).slice(0, 4).map(persona => (
                            <div className='score row' key={persona.id}>
                                <div className={"persona " + (winningPersona.name === persona.name ? "winning-persona" : "")}>{persona.name}
                                    {winningPersona.name === persona.name && <div className='medal'></div>}
                                </div>
                                <div className='points-bar'>
                                    <div className={"bar " + (winningPersona.name === persona.name ? "winning-bar" : "")} style={{
                                        width: toggleModal ? results.getScore(superlative.id, persona.id) / maxScore * 100 + '%' : '0%'
                                    }}></div>
                                    <div className={"points " + (winningPersona.name === persona.name ? "winning-points" : "")} style={{width: toggleModal ? 100 - results.getScore(superlative.id, persona.id) / maxScore * 100 + '%' : '0%'}}>{results.getScore(superlative.id, persona.id)} points</div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='point-breakdown'>
                        <h4>Why did {winningPersona.name} win?</h4>
                        <p className='why-block small three-quarters'>
                            When you designed the algorithm, you taught it to recognize 9 personality traits as the best for this award.
                            You also ranked one as most important, meaning it’s worth 9 points, with each additional trait decreasing in points from there.
                        </p>
                        <div className='breakdown-container'>
                            <div classname='breakdown-legend'><img src='/img/breakdown_legend.png' alt="Chart legend with arrows pointing from top to bottom, with points 1 to 9, 9 being most important, and 1 being least important"></img></div>
                            <div className='breakdown-chart two-thirds'>
                                {superlative.traitIds.map((traitId, index) => {
                                    const points = superlative.traitIds.length - index
                                    console.log(points)
                                    const trait = traitsById[traitId]
                                    console.log(trait)
                                    return <div key={traitId} className={
                                                winningPersona.traitIds.indexOf(traitId) >= 0 ? 'trait-breakdown winner' : 'trait-breakdown normal'}>
                                                {trait.title}
                                            </div>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResultsModal;

