import { useContext } from 'react';
import { LikelyContext } from '../context/LikelyContext';

const useGlobalState = () => {
    const [ state, setState ] = useContext(LikelyContext);

    const superlativesById = {}
    for (const superlative of state.superlatives) {
        superlativesById[superlative.id] = superlative
    }

    function setPrediction(personaId, prediction) {
        setState(state => {
            state.superlatives.find(s => s.id === prediction).predicted = personaId;
            return state
        })
    }

    function setComplete( stepId ) {
        setState(state => {
            state.steps[stepId].complete = true;
            return state
        })
    }

    function setCurrentTrait(traitCount) {
        setState(state => ({...state, currentTrait: traitCount}));
    }

    function setCurrentStep(stepVal) {
        setState(state => ({...state, currentStep: stepVal}));
    }

    function addSuperlativeTrait(superlativeId, traitId) {
        setState(state => {
            state.superlatives.find(s => s.id === superlativeId).traitIds.push(traitId)
            return state
        })
    }

    function setSuperlativeTraits(superlativeId, traitIds) {
        setState(state => {
            state.superlatives.find(s => s.id === superlativeId).traitIds = traitIds
            return state
        })
    }

    function createLookup(objects) {
        const lookup = {}

        for (const o of objects) {
            lookup[o.id] = o
        }

        return lookup
    }

    return {
        traits: state.traits,
        superlatives: state.superlatives,
        personas: state.personas,
        steps: state.steps,
        currentStep: state.currentStep,
        currentTrait: state.currentTrait,
        setCurrentTrait,
        setCurrentStep,
        setComplete,
        setPrediction,
        addSuperlativeTrait,
        setSuperlativeTraits,
        traitsById: createLookup(state.traits),
        superlativesById: createLookup(state.superlatives),
        personasById: createLookup(state.personas),
    }
};

export default useGlobalState;