import React, { useState } from 'react';
import useGlobalState from '../customHooks/globalState';
import Select from 'react-select';
import ProgressTracker from './progressTracker';
import Footer from './footer';

import '../components/css/predictions.css'

const customSelectStyles = {
    option: (provided, state) => ({
        color: '#361D38',
        background: state.isFocused ? '#FFF6F2' : state.isSelected ? '#FFF6F2' : 'white',
    })
};

function Prediction() {
    const { personas, superlatives, setPrediction } = useGlobalState();
    const [enableNext, setEnableNext] = useState(!superlatives.some(superlative => superlative.predicted === null));
    const [localPhoto, setLocalPhoto] = useState({
        1: '/img/noselection.png',
        2: '/img/noselection.png',
        3: '/img/noselection.png',
    }
    )
    function handleChange(selected, selectValue) {
        let prediction = selected.superlative;
        setPrediction(selected.value, prediction);
        setLocalPhoto({ ...localPhoto, [selected.superlative]: selected.img })
        setEnableNext(!superlatives.some(superlative => superlative.predicted === null));
    }

    function personaToListItem(persona, superlative) {
        return persona ? {
            value: persona.id,
            label: persona.name,
            superlative: superlative.id,
            img: persona.img
        } : null;
    }

    return (
        <>
            <div className='component-wrapper palecoral_bg wavy'>

                <div className='header secondary-header'>
                    <h2 className="two-thirds">Who do you think will win?</h2>
                    <p className="two-thirds">Cast your votes to see how they compare with who your algorithm picks.</p>
                </div>

                    <div className="predict-container half two-thirds-mobile max-wide">
                        {superlatives.map((superlative, i) => (
                            <div className='prediction full' key={superlative.id}>
                                <div className='row no-wrap'>
                                    <div className='select-photo'>
                                        <img src={superlative.predicted ? personas.find(p => p.id == superlative.predicted).img : localPhoto[superlative.id]} alt={superlative.predicted ? personas.find(p => p.id == superlative.predicted).alt : "A placeholder image with a question mark"}></img>
                                    </div>
                                    <div className='select-details'>
                                        <h6 className='lilac'>{superlative.subtitle}</h6>
                                        <h4>{superlative.title}</h4>
                                        <Select
                                            isSearchable={false}
                                            className
                                            classNamePrefix="prediction"
                                            value={personaToListItem(personas.find(persona => persona.id == superlatives[i].predicted), superlative)}
                                            name={`prediction` + superlative.id}
                                            onChange={handleChange}
                                            options={personas.map(persona => (personaToListItem(persona, superlative)))}
                                            placeholder='Select your candidate'
                                            menuPlacement="auto"
                                            minMenuHeight="260"
                                            
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            <Footer enableNext={enableNext} />
        </>
    );
}

export default Prediction