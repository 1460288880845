import React, { createContext, useState } from 'react';

const LikelyContext = createContext([{}, () => {}]);

const LikelyProvider = ({ children }) => {
    const [state, setState] = useState({
        traits: [
            { id: '1', title: 'Adaptable', img: '/img/traits/adaptable.png', alt: "Illustration of a curly, green chameleon" },
            { id: '2', title: 'Adventurous', img: '/img/traits/adventurous.png', alt: "Illustration of a shiny compass"  },
            { id: '3', title: 'Aggressive', img: '/img/traits/aggressive.png', alt: "Illustration of a boxing glove, with a yellow punching flourish"  },
            { id: '4', title: 'Athletic', img: '/img/traits/athletic.png', alt: "Illustration of an athletic sneaker" },
            { id: '5', title: 'Brave', img: '/img/traits/brave.png', alt: "Illustration of a lion"  },
            { id: '6', title: 'Calculating', img: '/img/traits/calculated.png', alt: "Illustration of a dart target with an arrow stuck in the bulls-eye"  },
            { id: '7', title: 'Captivating', img: '/img/traits/captivating.png', alt: "Illustration of a magnet"  },
            { id: '8', title: 'Committed', img: '/img/traits/committed.png', alt: "Illustration of a sparkly engagement ring"},
            { id: '9', title: 'Creative', img: '/img/traits/creative.png', alt: "Illustration of an artist's paint palette and paintbrush"  },
            { id: '10', title: 'Daredevil', img: '/img/traits/daredevil.png', alt: "Illustration of a green motorbike, propped up on the back wheel"  },
            { id: '11', title: 'Dedicated', img: '/img/traits/dedicated.png', alt: "Illustration of a long list, with three lines checked off"  },
            { id: '12', title: 'Defiant', img: '/img/traits/defiant.png', alt: "Illustration of a hand in a fist"  },
            { id: '13', title: 'Determined', img: '/img/traits/determined.png', alt: "Illustration of a dandelion growing out of a crack in a sidewalk"  },
            { id: '14', title: 'Disciplined', img: '/img/traits/disciplined.png', alt: "Illustration of a mountain with a flag on the peak"  },
            { id: '15', title: 'Driven', img: '/img/traits/driven.png', alt: "Illustration of a blue ribbon award"  },
            { id: '16', title: 'Funny', img: '/img/traits/funny.png', alt: "Illustration of a goofy pair of comedic glasses with fake eyebrows, big nose and mustache"  },
            { id: '17', title: 'Independent', img: '/img/traits/independent.png', alt: "Illustration of a howling wolf"   },
            { id: '18', title: 'Influential', img: '/img/traits/influential.png', alt: "Illustration of a cordless microphone"   },
            { id: '19', title: 'Multilingual', img: '/img/traits/multilingual.png', alt: "Illustration of a stack of books of different sizes. The top book has a drawing of the world on it."   },
            { id: '20', title: 'Musical', img: '/img/traits/musical.png', alt: "Illustration of two music notes"   },
            { id: '21', title: 'Playful', img: '/img/traits/playful.png', alt: "Illustration of playing cards and some board game pieces"   },
            { id: '22', title: 'Rebellious', img: '/img/traits/rebellious.png', alt: "Illustration of a can of spray paint, spraying out a cloud of paint"   },
            { id: '23', title: 'Relentless', img: '/img/traits/relentless.png', alt: "Illustration of a billy goat's face with horns and goatee"   },
            { id: '24', title: 'Theatrical', img: '/img/traits/theatrical.png', alt: "Illustration of a smiling drama mask"   },
            { id: '25', title: 'Tough', img: '/img/traits/tough.png', alt: "Illustration of a flexed arm"   },
            { id: '26', title: 'Trailblazing', img: '/img/traits/trailblazing.png', alt: "Illustration of a sailboat in the water"   },
            { id: '27', title: 'Vengeful', img: '/img/traits/vengeful.png', alt: "Illustration of a devil's tri-tipped spear"   },
        ],
    
        superlatives: [
            { id: '1',
            title: 'Go to a Top University',
            subtitle: 'Most likely to...',
            icon: '/img/cap.png',
            alt: "Playful illustration of a shiny green graduation cap, with a golden tassle hanging off the side", 
            traitIds: [],
            predicted: null },

            { id: '2',
            title: 'Go Viral',
            subtitle: 'Most likely to...',
            icon: '/img/like.png',
            alt: "Playful illustration of a shiny pink heart floating on top of an empty, purple speech bubble",
            traitIds: [],
            predicted: null },

            { id: '3',
            title: 'Troublemaker',
            subtitle: 'Biggest...',
            icon: '/img/tongue.png',
            alt: "Playful illustration of a cartoon mouth, angrily sticking its tongue out", 
            traitIds: [],
            predicted: null },
        ],

        personas: [
            {
                id: '1',
                name: 'Albert Einstein',
                title: 'German mathematician and physicist',
                blurb: 'Known as one of the smartest men in history for his development of the theory of relativity, Albert Einstein’s reputation might hide the fact that he dropped out of high school. He preferred taking his education into his own hands.',
                quote: '“The true sign of intelligence is not knowledge but imagination.”',
                img: '/img/personas/albert.png',
                traitIds: ['2', '17', '22'],
                alt: "Illustrative photo of Albert Einstein on a purple background. He has white, frizzy hair and a serious look",
            },
            {
                id: '2',
                name: 'Amelia Earhart',
                title: 'American Aviator',
                blurb: 'The first female pilot to fly alone across the Atlantic Ocean, Amelia Earhart made a high-flying name for herself as a pioneer in aviation and an activist for female pilots.',
                quote: '“I have often said that the lure of flying is the lure of beauty.”',
                img: '/img/personas/amelia.png',
                traitIds: ['1', '16', '19'],
                alt: "Illustrative photo of Amelia Earhart on a pink background. She wears a pilots hat and collared shirt with a tie",
            },
            {
                id: '3',
                name: 'Bruce Lee',
                title: 'American Actor',
                blurb: 'The one person you wouldn’t want to get into a fight with, the martial artist, actor and filmmaker Bruce Lee made a name for himself with his swift moves and dedication to teaching the ways of kung fu.',
                quote: '“Knowing is not enough, we must apply. Willing is not enough, we must do.”',
                img: '/img/personas/bruce.png',
                traitIds: ['6', '18', '27'],
                alt: "Illustrative photo of Bruce Lee on a green background. He is smiling menacingly",
            },
            {
                id: '4',
                name: 'Christopher Columbus ',
                title: 'Italian Colonizer',
                blurb: 'A bully of historic proportion, Columbus was ruthless and conniving when it came to getting what he wanted: land and gold. He enslaved and devasted the indigenous populations he encountered in the Americas and was later arrested by the Spanish Crown.',
                quote: '“By prevailing over all obstacles and distractions, one may unfailingly arrive at his chosen goal or destination.”',
                img: '/img/personas/christopher.png',
                traitIds: ['9', '10', '21'],
                alt: "Illustrative portrait of Christopher Columbus on a green background. He looks serious and smug. He wears a floppy colonial hat",
            },
            {
                id: '5',
                name: 'Cleopatra',
                title: 'Egyptian Ruler',
                blurb: 'One of history\'s most beloved baddies, Cleopatra (the last active ruler of the Ptolemaic Kingdom of Egypt) had a mischievous side. Supposedly, one of her favorite activities was wandering the streets of Alexandria in disguise and playing pranks on its residents.',
                quote: '“I will not be triumphed over.”', 
                img: '/img/personas/cleopatra.png',
                traitIds: ['8', '13', '26'],
                alt: "Illustrative portrait of Cleopatra on a purple background. She wears an ornamental headpiece and necklace with birds and cobras on them",
            },
            {
                id: '6',
                name: 'Jackie Robinson',
                title: 'American Baseball Player',
                blurb: 'Breaking barriers and hitting home runs at the same time, Jackie Robinson was the first Black athlete to play Major League Baseball in 1947 when he joined the Brooklyn Dodgers. He paved the way for the desegregation of professional sports.',
                quote: '“How you played in yesterday\'s game is all that counts.”',
                img: '/img/personas/jackie.png',
                traitIds: ['4', '12', '20'],
                alt: "Illustrative photo of Jackie Robinson on a blue background. He wears a baseball hat and uniform",
            },
            {
                id: '7',
                name: 'Marilyn Monroe',
                title: 'American Actress',
                blurb: 'An iconic movie star, Marilyn Monroe overcame a difficult childhood to become one of the most well-known names and faces in American film.',
                quote: '“If I\'d observed all the rules, I\'d never have got anywhere.”',
                img: '/img/personas/marilyn.png',
                traitIds: ['5', '11', '23'],
                alt: "Illustrative photo of Marilyn Monroe on a pink background. She has styled blonde hair and lipstick, wearing a pearl necklace",
            },
            {
                id: '8',
                name: 'Rosa Parks',
                title: 'American Activist',
                blurb: 'A rebel with a cause, Rosa Parks stood for what she believed in by refusing to surrender her seat on a segregated bus – sparking a nationwide effort to end racial segregation during the Civil Rights Movement.',
                quote: '“I would like to be remembered as a person who wanted to be free... so other people would be also free.”',
                img: '/img/personas/rosa.png',
                traitIds: ['7', '14', '25'],
                alt: "Illustrative photo of Rosa Parks on a blue background. She is smiling with her hair tied back, wearing glasses and a striped dress.",
            },
            {
                id: '9',
                name: 'William Shakespeare',
                title: 'British Playwright and Poet',
                blurb: 'One with words, William Shakespeare took the dramatic arts to a new level by authoring 37 plays, as well as several sonnets and poems. He was also a key member in the King\'s Men theater company. One with words, William Shakespeare took dramatic arts to a new level by authoring 37 plays and was a key member in the King\’s Men theater company. He also wrote many sonnets and poems.',
                quote: '“It is not in the stars to hold our destiny but in ourselves.”',
                img: '/img/personas/william.png',
                traitIds: ['3', '15', '24'],
                alt: "Illustrative portrait of William Shakespeare on a purple background. He looks serious, with a beard, a single earring, and a large collared shirt",
            },
        ],

        steps: {
            0: {
                title: 'Landing',
                width: '7%',
                url: '/'
            },
            1: {
                title: 'Learning Objectives',
                width: '7%',
                url: '/objectives'
            },
            2: {
                title: 'Introduction',
                width: '15%',
                url: '/introduction'
            },
            3: {
                title: 'Meet The Class',
                width: '25%',
                url: '/meet-the-class'
            },
            4:{
                title: 'Predict Winners',
                width: '36%',
                url: '/predict-winners',
                complete: false
            },
            
            5: {
                title: 'Categorize Traits',
                width: '46%',
                url: '/create-algorithm',
                complete: false
            },
            
            6: {
                title: 'Order the Traits',
                width: '57%',
                url: '/tune-algorithm'
            },
            
            7: {
                title: 'See your Results',
                width: '67%',
                url: '/results'
            },
            
            8: {
                title: 'Reflection',
                width: '78%',
                url: '/reflection'
            },
            
            9: {
                title: 'Real-life Algorithms',
                width: '88%',
                url: '/applying'
            },
            10: {
                title: 'Lesson Recap',
                width: '100%',
                url: '/recap'
            },
    },

    currentStep: 0,
    currentTrait: 0,
        
    });

    return (<LikelyContext.Provider value={[state, setState]}>
        {children}
    </LikelyContext.Provider>
    );
}

export { LikelyContext, LikelyProvider };

