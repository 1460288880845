
class Results {
    
    constructor(superlatives, personas) {
        this.scoreLookup = {}
        this.superlatives = superlatives
        this.personas = personas

        for (const superlative of superlatives) {
            this.scoreLookup[superlative.id] = {}
            for (const persona of personas) {
                this.scoreLookup[superlative.id][persona.id] = this.calcScore(persona, superlative)
            }
        }
    }

    calcScore(persona, superlative) {
        const traitCount = superlative.traitIds.length
        let score = 0

        for (let i = 0; i < traitCount; ++i) {
            const traitId = superlative.traitIds[i]

            if (persona.traitIds.indexOf(traitId) >= 0) {
                // This version assumes that superlative traitIds are ordered from least to most important
                // score += i + 1
                // This version assumes that superlative traitIds are ordered from most to least important
                score += traitCount - i
            }
        }

        return score
    }

    // Returns the score for a superlative/persona pair
    getScore(superlativeId, personaId) {
        return this.scoreLookup[superlativeId][personaId]
    }

    getSuperlativeWinner(superlativeId) {
        const scores = this.scoreLookup[superlativeId]
        let highestScore = -1
        let winningPersonaId = null

        for (const personaId in scores) {
            if (scores[personaId] > highestScore) {
                highestScore = scores[personaId]
                winningPersonaId = personaId
            }
        }

        return winningPersonaId
    }

    // getTraitScoreForSuperlative(superlativeId, traitId) {
    //     const superlative = this.superlatives.find(s => s.id === superlativeId)

    //     for (let i = 0; i < superlative.traitIds.length; ++i) {
    //         // if 
    //     }
    // }
}


function calcResults(superlatives, personas) {
    return new Results(superlatives, personas)
}

export default calcResults
