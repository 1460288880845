import React from 'react';
import Footer from '../components/footer';

import './css/introductions.css'

function Introductions() {
    return (
        <>
            <div className='component-wrapper intro-bg'>
                    <div className='intro-header wavy-top'>
                        <span className='intro-subhead'>Welcome To</span>
                        <h1 className="three-quarters">Millennium Middle School</h1>
                        <img className='yearbook' src='/img/intro_header.png' alt="Illustration of two copies of a class yearbook - one open, and one closed. The yearbook title says 'going down in history'. The open yearbook has a photo grid with some scribbled notes and stickers."></img>
                    </div>
            
                    
                    <div className='row two-thirds padding-top-6 max-wide'>
                        <h3>It’s time for the class awards!</h3>
                        <p>Every school year, the students at Millennium Middle School vote for class awards – like “Most Likely to Succeed” or “Most Artistic”. This year, the yearbook committee wants to create an algorithm called the Most Likely Machine to help pick the winners. But not everyone thinks it’s a good idea.</p>
                        <h3>The yearbook committee doesn’t agree</h3>
                        <p>Some students think the Most Likely Machine algorithm will make it easier and faster to decide the award winners. Other students think the algorithm will be biased and pick the wrong winners.</p>
                    </div>
            
                    <div className='intro-two-images row two-thirds padding-top-6 max-wide'>
                        <div className='half full-mobile'>
                            <img src='/img/intro_person1.png' alt="Abstract illustration of Black woman with wavy afro. Text on top says 'Algorithms will make things easier'"></img>
                        </div>
                        <div className='half full-mobile'>
                            <img src='/img/intro_person2.png' alt="Abstract illustration of white man with short red hair. Text on top says 'Algorithms will make things biased'"></img>
                        </div>
                    </div>
                    
                    <div className='row two-thirds padding-top-6 padding-bottom-6 max-wide'>
                        <h3>What do you think?</h3>
                        <p>Help Millennium Middle School design an algorithm for their class awards.
                        First, vote for who you think should win each award.
                        Then, help design the Most Likely Machine algorithm and compare your results.
                        Hint...you might be rewriting history in the process!</p>
                    </div>

                    <div className='awards-intro full'>
                            <h3 className='padding-top-14'>This year's awards</h3>
                            <div className='row two-thirds padding-bottom-14 max-wide'>
                                <div className='third'>
                                    <img className='smaller' src='/img/intro_superlative1.png' alt="Playful illustration of a shiny green graduation cap, with a golden tassle hanging off the side"></img>
                                </div>
                                <div className='third mobile-spacing'>
                                    <img className='smaller' src='/img/intro_superlative2.png' alt="Playful illustration of a cartoon mouth, angrily sticking its tongue out"></img>
                                </div>
                                <div className='third mobile-spacing'>
                                    <img className='smaller'src='/img/intro_superlative3.png' alt="Playful illustration of a shiny pink heart floating on top of an empty, purple speech bubble"></img>
                                </div>
                            </div>
                    </div>

                <div className='row two-thirds padding-top-14 max-wide'>
                    <h2>First, what’s an algorithm?</h2>
                    <p>Algorithms are a set of instructions that change one set of information to produce a new set of information – like  a recipe!</p>
                    <div className='full'>
                        <img src='/img/intro_cake.png' alt="Illustration of a frosted strawberry cake alongside a recipe card. The recipe title says 'Cake Algorithm' with three columns listing input, steps and output. The input lists 'flour, sugar, eggs, milk, butter'. The steps lists '1. Preheat oven 2. Mix dry ingredients 3. Mix wet ingredients 4. Mix wet into dry ingredients 5. etc. The output lists 'finished cake'."></img>
                    </div>
                    <p className='padding-top-2'>You can also think about algorithms as the rules and steps for making a decision. There are many algorithms already in your daily life. For example, the steps you take when getting dressed in the morning (shirt + jeans + socks + shoes) is an example of an algorithm! What are some algorithms you use every day?</p>
                    <h3>Why do we use algorithms?</h3>
                    <p>Algorithms can help us make decisions and solve problems more quickly and easily. Imagine having to re-create a new cake recipe every time you bake. With a recipe – or algorithm – we can repeat the same steps to save time and effort.</p>
                    <h3>What are computer algorithms?</h3>
                    <p>In computers, algorithm instructions are written in code and made automatically repeatable. Computers can use algorithms to organize huge amounts of information and make decisions very quickly.
                    <br></br><br></br>You’ve probably seen some of these common algorithms before...</p>
                </div>
                    <div className='row two-thirds padding-bottom-14 max-wide'>
                        <div className='intro-pic half full-mobile stack-mobile'>
                            <img src='/img/intro_google.png' alt="Illustration of a laptop. The laptop screen shows a search engine with a tennis shoe as a result"></img>
                            <h4 className='padding-top-2'>Google Search</h4>
                            <p className='small'>Doesn’t Google always seem to know what you’re thinking when you type in a search? That’s because Google uses an algorithm to organize all of the questions people ask it and generate the best search results for your question.</p>
                        </div>
                        <div className='intro-pic half full-mobile stack-mobile'>
                            <img src='/img/intro_shopping.png' alt="Illustration of a cellphone with a digital credit card on screen"></img>
                            <h4 className='padding-top-2'>Safe online shopping</h4>
                            <p className='small'>When you shop online, you share private information like your bank account and home address with strangers. How does that information stay safe? Online stores use algorithms to encrypt – or scramble – your information and share it securely.</p>
                        </div>
                    </div>
               
            </div>
            <Footer />
        </>
    )
}

export default Introductions