import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import useGlobalState from "../customHooks/globalState";
import '../components/css/fineTuner.css';
import ProgressTracker from './progressTracker';
import Footer from '../components/footer';
import { ReactComponent as MoveSVG } from '../img/move.svg';

let itemPosition = [1, 2, 3, 4, 5, 6, 7, 8, 9];

//Helper Function for the Reorder
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function FineTuner() {
  const { superlatives, superlativesById, traitsById, setSuperlativeTraits } = useGlobalState();
  const [selectedTab, setSelectedTab] = useState(0)

  //Helper Function for Setting State
  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    // console.log('drag end', result)

    const superlative = superlativesById[result.destination.droppableId]

    // console.log('superlative ' + superlative.title + ':')
    // console.log(`Moved from index ${result.source.index} to index ${result.destination.index}`)

    const reorderedTraitIds = reorder(superlative.traitIds, result.source.index, result.destination.index)

    setSuperlativeTraits(superlative.id, reorderedTraitIds)
  }

  return (
    <>
      <div className='component-wrapper palecoral_bg wavy'>

          <div className='header'>
            <h2 className="two-thirds main-title">Time to fine-tune your algorithm!</h2>
            <p className="title-text">Order the traits so the algorithm knows which are more important.</p>
          </div>

          <div className='tunerContainer half'>
            <div className='tab-group'>
              <div className={selectedTab === 0 ? 'tab activeTab' : 'tab'} onClick={() => setSelectedTab(0)}><img src='/img/cap.png' alt="Illustration of a graduation cap"></img><span className="tab-title">Top University</span></div>
              <div className={selectedTab === 1 ? 'tab activeTab' : 'tab'} onClick={() => setSelectedTab(1)}><img src='/img/like.png' alt="Illustration of a heart floating on a speech bubble"></img><span className="tab-title">Go Viral</span></div>
              <div className={selectedTab === 2 ? 'tab activeTab' : 'tab'} onClick={() => setSelectedTab(2)}><img src='/img/tongue.png' alt="Illustration of a mouth with tongue sticking out"></img><span className="tab-title">Troublemaker</span></div>
            </div>

            <DragDropContext onDragEnd={onDragEnd}>
              {superlatives.map((superlative, i) => (
                <div key={i} className={selectedTab === i ? 'visibleSuperlative' : 'hiddenSuperlative'} >
                  <Droppable key={superlative.id} droppableId={superlative.id} type={`Superlative ${superlative.id}`}>
                    {(provided) => {
                      return <div ref={provided.innerRef} className='superlative-container'>
                        <div className='position-column'>
                          {itemPosition.map((item, i) => (
                            <div key={i} className='position'>
                              <div className='position-item'>{item}</div>
                            </div>
                          ))}
                        </div>
                        <div className='trait-column'>
                          {superlative.traitIds.map((traitId, index) => (
                            <>
                              <Draggable draggableId={traitId} key={traitId} index={index} type={`Superlative ${superlative.id}`} >
                                {(provided, snapshot) => (
                                  <div
                                    className={snapshot.isDragging ? 'trait --isDragging' : 'trait'}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <p className="trait-text">{traitsById[traitId].title}</p>
                                    <MoveSVG />
                                  </div>
                                )}
                              </Draggable>
                            </>
                          ))}
                          {provided.placeholder}
                        </div>
                      </div>
                    }}
                  </Droppable>
                </div>
              ))}
            </DragDropContext>
          </div>
        <Footer />
      </div>
    </>
  );
}

export default FineTuner;
