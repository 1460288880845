import React from 'react'
import { useHistory } from 'react-router-dom';
import useGlobalState from '../customHooks/globalState';
import { TraitDropper } from './traitDropper';


// const styled = {
//     color: '#FFFFFF',
//     backgroundColor: '#F85E46',
//     width: 'auto',
//     minWidth: 144,
//     height: 48,
//     borderRadius: 48,
//     border: 'none',
//     marginRight: '2%',
//     fontFamily: 'sofia-pro',
//     fontWeight: '800',
//     fontSize: '.9em',
//     textTransform: 'uppercase',
//     letterSpacing: '2px',
//     marginLeft: 'auto',
//     order: '2',
// }

// const hovering = {
//     backgroundColor: '#FFFFFF',
// }

// const hidden = {
//     color: '#FFFFFF',
//     backgroundColor: '#F85E46',
//     width: 'auto',
//     minWidth: 144,
//     height: 48,
//     borderRadius: 48,
//     border: 'none',
//     marginRight: '2%',
//     fontFamily: 'sofia-pro',
//     fontWeight: '800',
//     fontSize: '.9em',
//     textTransform: 'uppercase',
//     letterSpacing: '2px',
//     opacity: '.2'
// }

function Footer(props) {
    let { enableNext } = props;
    let history = useHistory();

    const { superlatives, currentStep, setCurrentStep, steps, setCurrentTrait } = useGlobalState();  
    
    function nextStep() {
        let nextStep = currentStep + 1;
        setCurrentStep(nextStep);
        history.push(`${steps[nextStep].url}`)
    } 

    function resetArrays() {
        superlatives.forEach(superlative => superlative.traitIds = []);
        setCurrentTrait(0);
        history.push('/create-algorithm')
    }

    function resetTraits() {
        superlatives.forEach(superlative => superlative.traitIds = []);
        setCurrentTrait(0);
    }


    let button;
    let reset;

    if (currentStep === 5) {
        reset = <h5 className="reset-button" onClick={resetTraits}><a>Reset Traits</a></h5>  
    }

    if (enableNext === false) {
        if (currentStep === 5) {
            button = <button className={"footer-button button-disabled two-buttons"} onClick={nextStep}>Next</button>  
        } else {
            button = <button className={"footer-button button-disabled"} onClick={nextStep}>Next</button> 
        }
    } else if (enableNext === true) {
        if (currentStep === 5) {
            button = <button className={"footer-button two-buttons"} onClick={nextStep}>Next</button>  
        } else {
            button = <button className={"footer-button"} onClick={nextStep}>Next</button> 
        }
    } else if (currentStep === 6) {
        button = <button className={"footer-button"} onClick={nextStep}>Run Your Algorithm</button>
        reset = <button className={"hide"}></button>
    } else if (currentStep === 10) {
        button = <button className={"footer-button"} onClick={resetArrays}>Build Algorithm Again</button>
        reset = <button className={"hide"}></button>
    } else {
        button = <button className={"footer-button"} onClick={nextStep}>Next</button>
        reset = <button className={"hide"}></button>
    }

    return (
        <>
        <div className='footer'>
         {reset}
         {button}
        </div>
        </>
    )
}

export default Footer