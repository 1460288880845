import React from 'react';
import useGlobalState from '../customHooks/globalState';
import ProgressTracker from './progressTracker';
import Footer from '../components/footer';
import './css/apply.css'

function Apply() {
    const { superlatives } = useGlobalState();

    return (
        <>
            <div className='component-wrapper'>
                    <div className='apply-container'>
                        <div className='header secondary-header header-bg-flex'>
                            <h1 className='main-title'>Algorithms gone wrong</h1>
                            <p className='title-text'>Let’s look at some real-world examples of when algorithms make mistakes.</p>
                        </div>
                        <div className='apply-body white_bg'>
                            <div className='superlative-section three-quarters'>
                                <div className='superlative-one details'>
                                    <div className='left-col'>
                                        <img src={superlatives[0].icon} alt={superlatives[0].alt}></img>
                                        <h5 className='lilac'>{superlatives[0].subtitle}</h5>
                                        <h4>{superlatives[0].title}</h4>
                                    </div>
                                    <div className='right-col'>
                                        <img src='/img/apply_sup1.png' alt="Abstract green header image with the title 'Unfair grading'"></img>
                                        <h4>What happened?</h4>
                                        <p>In England, an important national test for high schoolers was canceled because of the COVID-19 pandemic. Instead, teachers decided each students’ test score. But the government didn’t trust teachers to give an honest answer. They decided to use an algorithm to predict student’s scores using information including each school’s past test scores. The result? The algorithm downgraded 40% of the grades teachers gave their students.</p>
                                        <h4>What's the problem?</h4>
                                        <p>What happened in the past doesn’t always predict the future. Using test information from previous years limited test scores and took away the chance for students to improve.</p>
                                        <ul className='think-about-it ul1'>
                                            <h5>Think About It</h5>
                                            <li>What if an algorithm predicted that you would fail a test before you even took it? </li>
                                            <li>What if a school or college rejected you because of that prediction?</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='superlative-two details'>
                                    <div className='left-col'>
                                        <img src={superlatives[1].icon} alt={superlatives[1].alt}></img>
                                        <h5 className='lilac'>{superlatives[1].subtitle}</h5>
                                        <h4>{superlatives[1].title}</h4>
                                    </div>
                                    <div className='right-col'>
                                        <img src='/img/apply_sup2.png' alt="Abstract purple header image with the title 'Harmful videos'"></img>
                                        <h4>What happened?</h4>
                                        <p>YouTube always seems to have an exciting video for you to watch next. Because YouTube makes money from advertisements on videos, it wants your attention for as long as possible so that you see more ads – and they make more money. So how do they keep you hooked? YouTube uses a computer algorithm to suggest the next video. But because people like surprising or emotional videos, the algorithm is designed to suggest more and more extreme content.</p>
                                        <h4>What's the problem?</h4>
                                        <p>Extreme videos often contain false information or harmful content. For example, videos of dangerous Internet challenges or videos from political candidates with racist or violent messages.</p>
                                        <ul className='think-about-it ul2'>
                                            <h5>Think About It</h5>
                                            <li>Have you ever spent hours watching videos on YouTube?</li>
                                            <li>How do these videos change the way you think?</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='superlative-two details'>
                                    <div className='left-col'>
                                        <img src={superlatives[2].icon} alt={superlatives[2].alt}></img>
                                        <h5 className='lilac'>{superlatives[2].subtitle}</h5>
                                        <h4>{superlatives[2].title}</h4>
                                    </div>
                                    <div className='right-col'>
                                        <img src='/img/apply_sup3.png' alt="Abstract pink header image with the title 'Mistakes predicting crime'"></img>
                                        <h4>What happened?</h4>
                                        <p>Almost every state uses algorithms to help the government enforce laws. For example, algorithms might decide which neighborhoods police will patrol, how to sentence people who break the law, or predict whether someone would commit a crime in the future. These are serious decisions.</p>
                                        <h4>What's the problem?</h4>
                                        <p>These algorithms are often wrong. While human judges are trained to consider each person’s unique history, an algorithm can only make decisions using the limited information that it is taught. Remember that algorithms reflect the opinion of who created them, so they can spread and reinforce unfairness. For example, an algorithm in Florida and Wisconsin incorrectly predicted that Black people were more likely to break the law than white people.</p>
                                        <ul className='think-about-it ul3'>
                                            <h5>Think About It</h5>
                                            <li>How would you feel if an algorithm predicted that you would break the law?</li>
                                            <li>What might happen if algorithmic bias is repeated millions or billions of times, across a community or country?</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
            </div>
        </>
    )
}

export default Apply