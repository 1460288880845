import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useGlobalState from '../customHooks/globalState';

import './css/landing.css'
import '../App.css'

function Landing() {
    let history = useHistory();
    let location = useLocation();

    const { currentStep, setCurrentStep, steps } = useGlobalState();
    useEffect(() => {
        setCurrentStep(0);
    }, [location.pathname])


    return (
        <>

            <div className='landing-container plum-bg'>
                <div className="landing-screen three-quarters">
                    <div className='landing-content'>
                        <img src='/img/MLM-title.svg' alt="Handwritten script title reading 'Most Likely Machine'"></img>
                        <h3 className='lilac three-quarters'>Wise up to algorithms</h3>
                        <p className='white three-quarters'>Algorithms power the world, but they can make mistakes. Build your own algorithm and see how it impacts the future – for good and for bad.</p>
                        <button className='wide' onClick={() => history.push(`${steps[currentStep + 1].url}`)}>LET'S GO</button>
                    </div>
                    <div className='top-frame'><img src='/img/landing_topstack.png' alt=" Illustration of polaroid film with written text that says 'trailblazing' and 'rebellious'"></img></div>
                    <div className='left-frame'><img src='/img/landing_leftstack.png' alt="Illustration of polaroid film with written text that says 'creative' and 'committed'"></img></div>
                    <div className='right-frame'><img src='/img/landing_rightstack.png' alt="Illustration of polaroid film with written text that says 'aggressive'"></img></div>
                
                </div>
            </div>

        </>
    )
}

export default Landing
