import React from 'react';


import { ReactComponent as CloseSVG } from '../img/close.svg';
import { ReactComponent as TwitterSVG } from '../img/twitter.svg';
import { ReactComponent as FacebookSVG } from '../img/facebook.svg';
import { ReactComponent as LinkedinSVG } from '../img/linkedin.svg';


import '../components/css/about.css'

const About = (props) => {
  const { closeAbout } = props;

  return (
    <div className="about__Overlay">
      <div className='about_wrapper'>
      <div className="close" onClick={closeAbout}><span role="icon" aria-label="Close Icon"></span><CloseSVG /></div>
        <div className='upper-half'>
          <img src='/img/about_img.png' alt="Most-Likely Machine logo next to Artefact Logo"></img>
          <p className="half">The Most Likely Machine is a digital education prototype created by Artefact.</p>
        </div>
        <div className='lower-half'>
          <div className='text-columns two-thirds'>
            <div className='col'>
              <h4>How to use the site</h4>
              <p className='small'>Designed for both individual and collective classroom use, we hope the Most Likely Machine prototype inspires both students and teachers. As a conceptual tool, we encourage educators to facilitate and build on the material. Learn more about <a href="https://www.artefactgroup.com/case-studies/most-likely-machine/" target="_blank">our process</a> creating the experience.</p>
            </div>
            <div className='col'>
              <h4>Discover our work</h4>
              <p className='small'>We envision and design learning experiences that are impactful, empower teachers, serve the whole student, and support lifelong learning. <a href="https://www.artefactgroup.com/work/#education" target="_blank">Discover our work</a> with partners like Arizona State University and Global Online Academy.</p>
            </div>
            <div className='col'>
              <h4>About Artefact</h4>
              <p className='small'>Artefact is a responsible strategy and design firm. We help your company shape the future with confidence and craft the products and experiences that bring your vision to life. Learn more at <a href="https://www.artefactgroup.com/" target="_blank">www.artefactgroup.com</a></p>
            </div>
          </div>
          <div className='share'>
            <h5>SHARE EXPERIENCE</h5>
            <div className="button-group">
              <div className="icon-button share-btn"><a href="https://twitter.com/artefactgroup" target="_blank"><TwitterSVG /></a></div>
              <div className="icon-button share-btn"><a href="https://www.facebook.com/artefactgroup" target="_blank"><FacebookSVG /></a></div>
              <div className="icon-button share-btn"><a href="https://www.linkedin.com/company/artefact/" target="_blank"><LinkedinSVG /></a></div>
            </div>
          </div>
          <p className="legal padding-bottom-2">This work is licensed under a <a href="https://creativecommons.org/licenses/by-nc/4.0/" target="_blank">Creative Commons Attribution-NonCommercial 4.0 International License.</a></p>
        </div>
      </div>
    </div>
  );
};

export default About;