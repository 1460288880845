import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import useGlobalState from '../customHooks/globalState';
import About from './about'
import '../components/css/progressTracker.css'

import { ReactComponent as BackSVG } from '../img/back.svg';
import { ReactComponent as AboutSVG } from '../img/info.svg';



function ProgressTracker() {
    const { currentStep, setCurrentStep, steps } = useGlobalState();
    const [aboutOpen, setAboutOpen] = useState(false);

    let history = useHistory();
    let location = useLocation();

    function noScroll() {
        document.body.classList.add('noScroll');
        document.getElementById("body-scroll").classList.add('noScroll');
    }

    function setScroll() {
        document.body.classList.remove('noScroll');
        // document.getElementById("body-scroll").classList.remove('noScroll');
    }

    //Get current step from URL (instead of state to retain position if page is refreshed)
    function initStep() {
        if (location.pathname === '/') {
            setCurrentStep(0);
        } else if (location.pathname === '/objectives') {
            setCurrentStep(1);
        } else if (location.pathname === '/introduction') {
            setCurrentStep(2);
        } else if (location.pathname === '/meet-the-class') {
            setCurrentStep(3);
        } else if (location.pathname === '/predict-winners') {
            setCurrentStep(4);
        } else if (location.pathname === '/create-algorithm') {
            setCurrentStep(5);
        } else if (location.pathname === '/tune-algorithm') {
            setCurrentStep(6);
        } else if (location.pathname === '/results') {
            setCurrentStep(7);
        } else if (location.pathname === '/reflection') {
            setCurrentStep(8);
        } else if (location.pathname === '/applying') {
            setCurrentStep(9);
        } else if (location.pathname === '/recap'){
            setCurrentStep(10);
        } else {
            setCurrentStep(null);
        }
    }

    //Check step with URL changes
    useEffect(() => {
        initStep();
    }, [location.pathname])


    function prevStep() {
        let prevStep = currentStep - 1;
        history.push(`${steps[prevStep].url}`)
    }

    const styled = {
        width: steps[currentStep].width,
        transition: 'all 800ms ease-out'
    }
    
    return (
        <>
            <div id="body-scroll" className="progressTracker__container">
                <div className="icon-button" onClick={prevStep}><span role="icon" aria-label="Back Icon"></span><BackSVG /></div>
                <div className='progressTracker__stepContainer'>
                    <div className="current-step">Step {currentStep} of 10</div>
                    <div className='page-title'>{steps[currentStep].title}</div>
                    <div className='progress-bar'>
                        <div className='node-container'>
                            <div className='1'></div>
                            <div className='2'></div>
                            <div className='3'></div>
                            <div className='4'></div>
                            <div className='5'></div>
                            <div className='6'></div>
                            <div className='7'></div>
                            <div className='8'></div>
                            <div className='9'></div>
                            <div className='10'></div>
                        </div>
                        <div className='tracker-percent' style={styled}>
                            <div className='head'></div>
                        </div>
                    </div>
                </div>

                <div className="icon-button" onClick={() => {
                    setAboutOpen(true); 
                    noScroll();
                }}>
                    <span role="icon" aria-label="Info Icon"></span>
                    <AboutSVG />
                </div>
            </div>

            {aboutOpen && (<About closeAbout={() => { 
                setAboutOpen(false); 
                setScroll();
            }}></About>)}
        </>
    )
}

export default ProgressTracker