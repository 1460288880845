import React from 'react';
import ProgressTracker from '../components/progressTracker';
import Footer from '../components/footer';

import './css/objectives.css';

function Objectives() {
    return (
        <>
                <div className='component-wrapper'>
                    <div className='header header-bg objectives-header'>
                        <h1>You will learn...</h1>
                    </div>
           
                    <div className='objectives-wrapper row full'>
                        <div className='objectives-container row full'>
                            <div className='flex-card-copy'>
                                <div className='what-illo shrink'><span role="img" aria-label="Illustration of a frosted strawberry cake on a dessert stand"></span></div>
                                <div className='objective-content'>
                                    <h4>What are algorithms?</h4>
                                </div>
                            </div>
                            <div className='flex-card-copy'>
                                <div className='used-illo shrink'><span role="img" aria-label="Illustration of a laptop. The laptop screen shows a search engine with a tennis shoe as a result"></span></div>
                                <div className='objective-content'>
                                    <h4>What are they used for?</h4>
                                </div>
                            </div>
                            <div className='flex-card-copy'>
                                <div className='bias-illo shrink'><span role="img" aria-label="Playful illustration of a man and woman standing atop two bar-chart bars"></span></div>
                                <div className='objective-content'>
                                    <h4>What is algorithmic bias?</h4>
                                </div>
                            </div>
                            <div className='flex-card-copy'>
                                <div className='why-illo shrink'><span role="img" aria-label="Illustration of an open envelope with an unfolded sheet of paper that says 'DENIED'"></span></div>
                                <div className='objective-content'>
                                    <h4>Why does it matter?</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <Footer />
        </>

    )
}

export default Objectives