import React, { useState, useEffect } from 'react';
import useGlobalState from '../customHooks/globalState';

import Footer from '../components/footer';
import '../components/css/traitDropper.css';

import '../components/DragDropTouch.js';

export function TraitDropper() {
    const { traits, superlatives, currentTrait, setCurrentTrait, addSuperlativeTrait } = useGlobalState();
    const [enableNext, setEnableNext] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const trait = traits[currentTrait]
    const nextTrait = traits[currentTrait + 1];

    useEffect(() => {
        if (isLoading) {
            setTimeout(() => {
                setIsLoading(false);
            }, 25);
        }
    })

    const dragStart = event => {
        var element = event.target;
        requestAnimationFrame(() => {
            element.classList.add("dragging");
        });
    }

    const dragEnd = event => {
        event.target.classList.remove("dragging");
    }

    const dragEnter = event => {
        const element = event.target;
        const [type, id] = element.id.split('-');
        
        if(superlatives.find(superlative => superlative.id === id).traitIds.length < 9) {
            element.classList.add("dragover");
            event.preventDefault();
        }
    }

    const dragOver = event => {
        const element = event.target;
        const [type, id] = element.id.split('-');
        
        if(superlatives.find(superlative => superlative.id === id).traitIds.length < 9) {
            event.preventDefault();
        }
    }

    const dragLeave = event => {
        event.target.classList.remove("dragover");
    }

    const drop = event => {
        var element = event.target;
        element.classList.remove("dragover");

        const [type, id] = element.id.split('-');

        if(superlatives.find(superlative => superlative.id === id).traitIds.length < 9) {
            addSuperlativeTrait(id, traits[currentTrait].id);
            setCurrentTrait(currentTrait + 1)
            setIsLoading(true);
        }
    }


    const onDragEnd = result => {
        if (result.destination && result.destination !== result.source) {
            // superlatives
            const [type, id] = result.destination.droppableId.split('-')

            if (type === 'superlative') {
                addSuperlativeTrait(id, result.draggableId)
                setCurrentTrait(currentTrait + 1)
                setIsLoading(true);
            }
        }

    }

        // Cheat option to prefill
        const cheat = () => {
            for (let i = currentTrait; i < traits.length; ++i) {
                while (true) {
                    // Pick a random superlative
                    const index = Math.round(Math.random() * 2)
                    if (superlatives[index].traitIds.length < 9) {
                        addSuperlativeTrait(superlatives[index].id, traits[i].id)
                        break
                    }
                }
            }
    
            setCurrentTrait(traits.length)
        }
    
        //Style for Cheat Button
        const styled = {
            bottom: '12%',
            // position: 'absolute',
            left: '2%'
        }

    //Function to disable Next if TraitIDs are empty
    useEffect(() => {
        var completed = [];

        superlatives.forEach(superlative => completed.push(superlative.traitIds.length >= 9));
        setEnableNext(!completed.includes(false));
    });

    return <>
        <div className='component-wrapper palecoral_bg traitDropper wavy'>
        {/* <button style={styled} onClick={cheat}>cheat!</button> */}
            <div className='header trait-header'>
                <h2 className="two-thirds">Match each personality trait to an award.</h2>
                <p className="two-thirds">This information trains the algorithm to pick the winners.</p>
            </div>
            <div className="drag-wrapper">
                <div className="traitsDraggable">
                    <div className={`cardStack ${!traits[currentTrait + 2] ? "last" : ""}`}>
                        {traits[currentTrait + 1] ? <div class="traitCard">
                            <img src={traits[currentTrait + 1].img} alt={traits[currentTrait + 1].alt}></img>
                            <div className="trait-title">{traits[currentTrait + 1].title}</div>
                        </div> 
                        : 
                        (trait && <h2></h2>) }
                        {trait ? <div className="traitCard" draggable={true} onDragStart={dragStart} onDragEnd={dragEnd}>
                            <img src={trait.img} alt={trait.alt}></img>
                            <div className="trait-title">{trait.title}</div>
                        </div> 
                        : 
                        <div className="finished">
                            <h4 className="done-text">You're all done!</h4>
                            <img src="./img/sparkles.png" alt="Illustration of four yellow sparkles in varying sizes"></img>
                        </div>
                         }
                    </div>
                </div>

                <div className='droppableTargets three-thirds'>
                    {superlatives.map((superlative, index) => (
                        <div class={`droptarget ${superlative.traitIds.length < 9 ? 'enabled' : ''}`} id={`superlative-${superlative.id}`} key={index} onDragEnter={dragEnter} onDragOver={dragOver} onDragLeave={dragLeave} onDrop={drop} onClick={drop}>
                            <div class="superlative">       
                                <img className="sup-icon" src={superlative.icon} alt={superlative.alt} />
                                <div className='superlative-details'>
                                    <span className='most-likely'>{superlative.subtitle}</span>
                                    <h4 className='left sup-title'>{superlative.title}</h4>
                                    <div className='counter'>
                                            {[...Array(9)].map((element, index) => (
                                                <div key={index} className={index < superlative.traitIds.length ? "active" : "inactive"}></div>
                                            ))}
                                    </div>
                                    <div className='mobile-counter'>
                                            
                                                <h4 className="counter-nums">{superlative.traitIds.length + "/9"}</h4>
                                           
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            </div>
        <Footer enableNext={enableNext} />
    </>
}

